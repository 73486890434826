<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />

    <CHeaderBrand class="mx-auto d-lg-none site-logo" to="/dashboard">
      <!-- CIcon name="logo" height="48" alt="Logo"/ -->
      <img
        src="@/assets/images/liberty-resort-black.png"
        height="48"
        alt="LibertyResort"
      />
    </CHeaderBrand>
    <!-- delete this list  -->
    <!-- <CBadge @click="$router.push('area-master')">area master</CBadge> -->
    <!-- delete this list above  -->

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <h2 v-if="uid">{{ hotelName }}</h2>
        <h4 v-if="!uid">{{ hotelIdNotFound }}</h4>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <!-- <h2>管理画面</h2> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isAdminRole">
        <CButton color="info" square block size="sm" @click="onAdminButtonClicked()"
          >{{ $t("header.switchToSiteAdministrator") }}
        </CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isStaffRole">
        <CButton color="info" square block size="sm" @click="onStaffButtonClicked()"
          >{{ $t("header.switchToStaff") }}
        </CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isMemberRole">
        <CButton color="info" square block size="sm" @click="onMemberButtonCliked()"
          >{{ $t("header.switchToGeneralUser") }}
        </CButton>
      </CHeaderNavItem>
    </CHeaderNav>

    <div class="lg_section">
      <span class="mx-2 current_fg" @click="openLG()"
        ><CIcon :name="localFlag" width="30" class="m-auto" /><b
          class="icon-down"
          >🢓</b
        ></span
      >
      <transition name="fade">
        <ul class="fg_list" v-if="openFgList">
          <li>
            <span
              @click="
                $store.commit('clickLgJp');
                openFgList = false;
              "
              ><CIcon name="cif-jp" width="30" class="m-auto" />日本語</span
            >
          </li>
          <li>
            <span
              @click="
                $store.commit('clickLgEn');
                openFgList = false;
              "
              ><CIcon name="cif-us" width="30" class="m-auto" />English</span
            >
          </li>
          <li>
            <span
              @click="
                $store.commit('clickLgZh');
                openFgList = false;
              "
              ><CIcon name="cif-cn" width="30" class="m-auto" />中文</span
            >
          </li>
        </ul>
      </transition>
    </div>

    <CSubheader class="px-3">
      <BreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import axios from "axios";
import BreadcrumbRouter from '@/components/BreadcrumbRouter.vue';


const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
};

export default {
  name: "TheHeader",
  components: {
    BreadcrumbRouter
  },

  data() {
    return {
      fullName: "",
      loading: false,
      openFgList: false,
      langs: ["ja", "en", "zh"],
      isAdminRole: false,
      isStaffRole: false,
      isMemberRole: false,
      hotelName: "",
      hotelIdNotFound:""
    };
  },

  created() {
    this.buttonSwitchHandler();
  },

  methods: {

    // #region event

    openLG() {
      this.openFgList = !this.openFgList;
    },

    
    /**スタッフへ戻るボタン押下 */
    onStaffButtonClicked(){
      this.moveToStaffPage();
    },


    /**サイト管理者画面へ戻るボタン押下 */
    onAdminButtonClicked(){
      this.moveToAdminPage();
    },

    /**一般会員へ戻るボタン押下*/
    onMemberButtonCliked(){
      this.moveToMemberPage();
    },

    // #endregion event

    
    // #region method

    /**ボタンの表示切り替え */
    buttonSwitchHandler() {

      const roles = this.$keycloak.realmAccess.roles;

      if (roles.includes(ROLE.ADMIN)) {
        this.isAdminRole = true;
        return;
      }
      if (roles.includes(ROLE.STAFF)) {
        this.isStaffRole = true;
        return;
      }
      if (roles.includes(ROLE.MEMBER)) {
        this.isMemberRole = true;
        return;
      }
      this.isMemberRole = false;
    },

    /**スタッフ画面へ遷移 */
    moveToStaffPage() {
      //遷移前に宿IDをstoreから初期化させる
      this.$store.commit('setUid',"");
      console.log(this.uid);
      this.$router.push({
        path: "/staff/dashboard",
      });
    },

    /**一般ユーザ画面へ遷移 */
    moveToMemberPage() {
      //遷移前に宿IDをstoreから初期化させる
      this.$store.commit('setUid',"");
      this.$router.push({
        path: "/member/dashboard",
      });
    },

    /**サイト管理者画面へ遷移 */
    moveToAdminPage() {
      //遷移前に宿IDをstoreから初期化させる
      this.$store.commit('setUid',"");
      console.log(this.uid);
      this.$router.push({
        path: "/admin/dashboard",
      });
    },

    /** 宿ダッシュボード画面へ遷移 */
    moveToDashboardPage(uniqueId) {
      this.$router.push({
        path: "/operator/dashboard"
      });
      this.$store.commit('setUid',uniqueId);
      console.log(this.uid)
    },

    /**宿切替画面へ遷移 */
    moveToFacilitySwitchPage() {
      this.$router.push({
        path: "/operator/facility/switchList"
      }).catch(function(err){
        console.log(err);
      });
      console.log("宿切替画面へ");
      console.log(this.uid);
    },
    

    // #endregion method


    // #region request

    /**ロールのチェックと宿のuniqueIdの有無 */
    isFacilityUniqueId() {
      //アカウントが所持するロールを全て取得
      const roles = this.$keycloak.realmAccess.roles;

      // storeにhotelのuniqueIdが空の時
      if (!this.uid) {
        // NOTE:ロールは複数持つ場合があるため、優先順に処理していく

        // adminが含まれているときadminダッシュボードへ
        if (roles.includes(ROLE.ADMIN)) {
          this.moveToAdminPage();
          return;
        }
        // staffが含まれているときstaffダッシュボードへ
        if (roles.includes(ROLE.STAFF)) {
          this.moveToStaffPage();
          return;
        }
        // operatorが含まれているとき担当宿のuniqueId取得
        if (roles.includes(ROLE.OPERATOR)) {
          this.getUserFacilities(); //axios rest/current/hotel
          return;
        }
        // それ以外のロールの時
        alert("アクセスエラー\n一般ユーザーのダッシュボードへ移動します。");
        // memberダッシュボードへ
        this.moveToMemberPage();
      }

    // storeにhotelのuniqueIdが存在する時
      //ロールがスタッフ又はアドミンの場合はstoreのuniqueIdの宿情報を取得
      if (roles.includes(ROLE.STAFF) || roles.includes(ROLE.ADMIN)) {
        this.getFacilityInformation();
        return
      }
      //ロールがオペレーターのとき担当宿のuniqueId取得
      if (roles.includes(ROLE.OPERATOR)) {
        this.getUserFacilities();
        return;
      }
      //ロールが一般の時はmemberダッシュボードへ
      this.moveToMemberPage();
    },


    /**関連づいた宿取得 */
    getUserFacilities() {
      var urlRequest = `/rest/currentuser/hotels`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          // 0件の時
          if (resp.data.hotels.length === 0) {
            alert("ユーザーに宿情報が関連づいていません。\n一般アカウント画面に移動します。");
            this.moveToMemberPage();
            return;
          }
          // 2件以上の時
          if(resp.data.hotels.length >= 2){
            this.hotelIdNotFound = "宿を選択してください";
            this.moveToFacilitySwitchPage();
            return;
          }
          // 1件の時
          if(resp.data.hotels.length === 1){
            this.hotelName = resp.data.hotels[0].name;
            this.moveToDashboardPage(resp.data.hotels[0].uniqueId);
            return;
          }
          
          //それ以外の時
          this.moveToMemberPage();
        })
        .catch((err) => {
          console.log(err);
          alert(
            "ユーザーに宿情報が関連づいていません。\n一般アカウント画面に移動します。"
          );
          this.moveToMemberPage();
        });
    },


    /** 宿情報取得*/
    getFacilityInformation: function () {
      var urlRequest = "/rest/hotel/" + this.uid;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(resp.data);
          this.hotelName = resp.data.name;
        })
        .catch((err) => {
          console.log(err);
          this.moveToMemberPage();
        });
    },

    // #endregion request

  },

  watch:{

    // # region watch

    uid(){
      this.$nextTick(() => {
        this.getFacilityInformation();
      })
    }

    // # endregion watch
  },

  computed: {
    currentLg() {
      return this.$store.state.langsv;
    },

    uid(){
      return this.$store.state.uniqueId;
    },

    loginRole() {
      // オペレーターか管理者であればロール名を返す
      const roles = this.$keycloak.realmAccess.roles;
      let loginRole = "様";
      if (roles.includes(ROLE.ADMIN)) {
        loginRole = " (管理者)";
      } else if (roles.includes(ROLE.OPERATOR)) {
        loginRole = " (オペレータ)";
      }
      return loginRole;
    },
    localFlag() {
      var currentLg;
      this.$i18n.locale == "ja"
        ? (currentLg = "cif-jp")
        : this.$i18n.locale == "en"
        ? (currentLg = "cif-us")
        : (currentLg = "cif-cn");
      return currentLg;
    },
  },


  beforeMount() {
    this.isFacilityUniqueId();
  },
};
</script>
<style lang="scss">
.lg_section {
  margin: 10px 40px auto auto;
  position: relative;
  cursor: pointer;
  .icon-down {
    font-size: 1.8em;
    right: -11px;
    top: 12px;
    position: absolute;
    // bottom: 10px;
  }
  .current_fg {
    padding: 2px 4px 4px;
  }
  svg {
    border: 1px solid #cccccc;
  }
  .fg_list {
    position: absolute;
    right: 10px;
    top: 30px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    padding: 0 0 5px 0;
    margin: 0;
    background: #ffffff;

    li {
      list-style: none;
      min-width: 120px;
      margin: 5px 0;
      clear: both;
      span {
        float: left;
        margin: 0 5px;
        svg {
          margin-right: 10px !important;
          display: inline-block;
        }
      }
    }
  }
}
</style>
