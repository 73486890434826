<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      to="/dashboard"
    >
      <strong>{{ $t("nav.title") }}</strong>
    </CSidebarBrand>

    <CRenderFunction
      flat
      :content-to-render="navDatas"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import config from '@/config'
const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
};

export default {
  name: "TheSidebar",
  data() {
    return {
      /** アカウントが使用可能なルート情報 */
      routerData: [],

      /** 選択中ロール */
      selectedRole: "",
    };
  },

  created() {
    // ルート情報設定用に、選択中ロールに初期ロールを設定する
    this.selectedRole = this.initialRole;
    this.updateRouterData(this.selectedRole);
  },

  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    uid(){
      return this.$store.state.uniqueId;
    },

    navDatas() {
      // 選択中のロールでルート情報更新
      this.updateRouterData(this.selectedRole);

      const menuDataStructure = [
        {
          _name: "CSidebarNav",
          _children: this.routerData,
        },
      ];

      return menuDataStructure;
    },

    /** 表示用の初期ロールを取得 */
    initialRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      console.log(roles)

      // 優先度に応じたロールを返す
      if (roles.includes(ROLE.ADMIN)) return ROLE.ADMIN;
      if (roles.includes(ROLE.STAFF)) return ROLE.STAFF;
      if (roles.includes(ROLE.OPERATOR)) return ROLE.OPERATOR;
      return ROLE.MEMBER;
    },
  },

  methods: {

    /** ルート情報更新 */
    updateRouterData(selectedRole) {

      // hotelIdが空の時は、宿切替のみを表示
      if(!this.uid) {
        const allRouterDatas = [
          //#region ----- 宿管理 -----
          {
            _name: "CSidebarNavTitle",
            _children: [this.$t("routerName.FacilityManagement")],
            _class: "parent_tab",
            viewRole: [ROLE.OPERATOR],
            version: 1,
          },
          {
            _name: "CSidebarNavItem",
            name: this.$t("routerName.FacilitySwitch"),
            to: `/operator/facility/switchList`,
            icon: "cil-pencil",
            viewRole: [ROLE.OPERATOR],
            version: 1,
          },
          //#endregion ----- 宿管理 -----

          //#region ----- ログアウト -----
          {
            _name: "CSidebarNavTitle",
            _children: [this.$t("routerName.Logout")],
            _class: "parent_tab",
            viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
            version: 1,
          },
          {
            _name: "CSidebarNavItem",
            name: this.$t("routerName.Logout"),
            to: "/logout",
            icon: "cil-room",
            viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
            version: 1,
          },
          //#endregion ----- ログアウト -----
        ]
        // アカウントが所属するロールが使用できるルート情報に更新する
        this.routerData = allRouterDatas.filter((e) =>
          e.version <= config.VERSION && e.viewRole.some((v) => v == selectedRole)
        );

        return;
      }

      // hotelIdがstoreにセットされているとき
      // 全てのルート情報を設定
      this.debugLog(selectedRole);
      // NOTE: i18n対応のためここで設定すること
      const allRouterDatas = [

        //#region ----- ポータル -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Portal")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Dashboard"),
          to: "/operator/dashboard",
          icon: "cil-speedometer",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        //#endregion ----- ポータル -----


        //#region ----- 顧客管理 -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.CustomerManagement")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Reservation"),
          to: "/operator/reservation",
          icon: "cil-list",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Contact"),
          to: "/operator/contact",
          icon: "cil-chat-bubble",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Review"),
          to: "/operator/review",
          icon: "cil-thumb-up",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion ----- 顧客管理 -----

        //#region ----- 在庫調整 -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Inventory")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },

        //#region 部屋在庫調整
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.RoomInventoryEdit"),
          to: "/operator/roomInventory/edit",
          icon: "cil-exposure",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion 部屋在庫調整

        //#region オプション在庫調整
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.OptionInventoryEdit"),
          to: "/operator/optionInventory/edit",
          icon: "cil-exposure",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion オプション在庫調整

        //#endregion ----- 在庫調整 -----


        //#region ----- プラン管理 -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.PlanManagement")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },

        //#region プラン
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Plan"),
          to: "/operator/plan",
          icon: "cil-airplane-mode",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#region プラン

        //#endregion プランカテゴリ
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.PlanCategory"),
          to: "/operator/planCategory",
          icon: "cil-tags",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion プランカテゴリ

        //#region 部屋
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.RoomType"),
          to: "/operator/roomType",
          icon: "cil-bed",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion 部屋

        //#region 料金カレンダー
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.PriceCalendarEdit"),
          to: "/operator/priceCalendar/edit",
          icon: "cil-calendar",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion 料金カレンダー

        //#region キャンセル規定
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.CancelPolicy"),
          to: "/operator/cancelPolicy",
          icon: "cil-warning",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion キャンセル規定

        //#region オプション
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Option"),
          to: "/operator/option",
          icon: "cil-smile-plus",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion オプション

        //#region 質問
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Question"),
          to: "/operator/question",
          icon: "cil-comment-bubble",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion 質問

        //#region 年齢区分
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.AgePage"),
          to: "/operator/age/edit",
          icon: "cil-people",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        //#endregion 年齢区分

        //#endregion ----- プラン管理 -----


        //#region ----- メディア管理 -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.MediaManagement")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Image"),
          to: "/operator/image",
          icon: "cil-image",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        //#endregion ----- メディア管理 -----


        //#region ----- 宿管理 -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.FacilityManagement")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.FacilityEdit"),
          to: `/operator/facility/edit`,
          icon: "cil-home",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.FacilitySwitch"),
          to: `/operator/facility/switchList`,
          icon: "cil-elevator",
          viewRole: [ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Operator"),
          to: `/operator/operator`,
          icon: "cil-user",
          viewRole: [ROLE.STAFF, ROLE.OPERATOR],
          version: 2,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.FacilityArticle"),
          to: `/operator/facilityArticle`,
          icon: "cil-newspaper",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.FacilitySetting"),
          to: `/operator/facility/setting`,
          icon: "cil-settings",
          viewRole: [ROLE.ADMIN],
          version: 1,
        },
        //#endregion ----- 宿管理 -----

        //#region ----- 質問管理 -----
        // よくある質問
        //#endregion ----- 質問管理 -----

        //#region ----- 検索キーワード管理 -----
        // 検索キーワード
        //#endregion ----- 検索キーワード管理 -----

        //#region ----- 検索管理 -----
        // TODO: Sprint6開発予定
        // {
        //   _name: "CSidebarNavTitle",
        //   _children: [this.$t("nav.operator.tagTitle")],
        //   _class: "parent_tab",
        //   viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        // },
        // {
        //   _name: "CSidebarNavItem",
        //   name: this.$t("nav.operator.yadoTagList"),
        //   to: "/operator/yado-tag-list",
        //   icon: "cil-tags",
        //   viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        // },
        // {
        //   _name: "CSidebarNavItem",
        //   name: this.$t("nav.operator.searchList"),
        //   to: "/operator/search-list",
        //   icon: "cil-library-add",
        //   viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        // },
        //#endregion ----- 検索管理 -----

        //#region ----- お知らせ管理 -----
        // TODO: Sprint6開発予定
        // {
        //   _name: "CSidebarNavTitle",
        //   _children: [this.$t("nav.operator.informationManagement")],
        //   _class: "parent_tab",
        //   viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        // },
        // {
        //   _name: "CSidebarNavItem",
        //   name: this.$t("nav.operator.questionList"),
        //   to: "/operator/features-list",
        //   icon: "cil-comment-square",
        //   viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        // },
        // {
        //   _name: "CSidebarNavItem",
        //   name: this.$t("nav.operator.frequentQuestions"),
        //   to: "/operator/bath-list",
        //   icon: "cil-comment-square",
        //   viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        // },
        //#endregion ----- お知らせ管理 -----

        //#region ----- ログアウト -----
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Logout")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Logout"),
          to: "/logout",
          icon: "cil-room",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR],
          version: 1,
        },
        //#endregion ----- ログアウト -----

      ];

      // 使用可能なルート情報に更新する
      this.routerData = allRouterDatas.filter((e) =>
        e.version <= config.VERSION && e.viewRole.some((v) => v == selectedRole)
      );

    },

  },
};
</script>